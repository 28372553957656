import React from "react";
import ServiceSummaries from "./ServiceSummaries";
import icon from "../img/notice.png";

function Service() {
  return (
    <div className="w-[90%] md:w-[100%] mx-auto mb-0 md:mb-20" id="service">
      <h2 className="mb-2 md:mb-4">サービスの特徴</h2>
      <ServiceSummaries />
      <div className="bg-[#E3E2E2] w-[100%] md:w-[100%] mx-auto -mt-3 md:-mt-5 mb-4 rounded-xl text-left px-4 md:px-12 py-6 md:py-14 font-bold text-[14px] md:text-[22px]">
        <p>
          ※お客様のスマートフォンで入力された情報は<br></br>
          Beaver Deliveryシステムへ伝送されるため<br></br>
          店舗様で個人情報を管理していただく <br className="md:hidden" />
          必要はありません。
        </p>
      </div>
      <div className="bg-[#E3E2E2] mt-4 rounded-[5px] md:rounded-[17px] text-left px-4 md:px-8 py-4 font-bold text-[14px] md:text-[22px]">
        <p>※日本人のお客様もご利用可能です</p>
      </div>
      <div className="bg-[#E3E2E2] mt-4 rounded-[5px] md:rounded-[17px] text-left px-4 md:px-8 py-4 font-bold text-[14px] md:text-[22px]">
        <p>
          <span className="flex items-center gap-x-1">
            <img src={icon} alt="" />
            注意事項
          </span>
        </p>
        <p>海外へ配送する場合は免税対象外となります</p>
      </div>
    </div>
  );
}

export default Service;
