const RecommendationPoint = ({
  index,
  text,
}: {
  index: number;
  text: string;
}) => {
  return (
    <li className="flex items-center bg-white border-4 border-[#F6C544] text-[13px] md:text-[26px] font-bold mb-6 md:min-h-[5.5rem]">
      <span className="bg-[#F6C544] max-w-[33px] md:max-w-[77px] text-white mr-auto pl-[0.8rem] pr-[1rem] py-[0.6rem] md:py-[1.3rem] md:px-8 border-[#F6C544] m-[-1px] text-center">
        {index}
      </span>
      <span
        className="w-[100%] mx-auto"
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    </li>
  );
};

export default RecommendationPoint;
